import React from 'react';
import { Trans, translate } from 'react-i18next';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

class PanelHeader extends React.Component {
  render() {
    let { title, backPath } = this.props;

    return (
      <Card>
        <CardBody>
          <Row>
            <Col xs={9} md={9}>
              <h5 className="title m-0">{title}</h5>
            </Col>
            <Col xs={3} md={3}>
              <Link
                className="btn btn-default btn-neutral float-right m-0"
                to={backPath}
              >
                <i className="fa fa-arrow-left" /> <Trans>Back</Trans>
              </Link>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default translate('translations-fr')(PanelHeader);
