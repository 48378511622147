import Loadable from 'react-loadable';
import Spinner from '../components/Spinner/Spinner';

const userRoutes = [
  {
    path: '/me/profile',
    name: 'Profile',
    mini: 'PR',
    icon: 'users_single-02',
    component: Loadable({
      loader: () => import('../views/Profile/UserPage'),
      loading: Spinner
    })
  },
  {
    path: '/me/change-password',
    name: 'Change Password',
    mini: 'CP',
    icon: 'objects_key-25',
    component: Loadable({
      loader: () => import('../views/Profile/ChangePassword'),
      loading: Spinner
    })
  }
];

export default userRoutes;
