import React from 'react';

class Rank extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: null,
      rank: null
    };
  }

  componentWillMount() {
    let { items, rank } = this.props;
    this.setState({ items, rank });
  }

  componentWillReceiveProps({ items, rank }) {
    this.setState({ items, rank });
  }

  generateRank() {
    let { items, rank } = this.state;
    let diamonds = [];
    while (items > 0) {
      let active = items <= rank ? 'rank-active' : 'rank-deactivated';
      let v = items;
      diamonds.push(
        <div
          key={'rank-item-' + v}
          className={'rank-diamond ' + active}
          onClick={() => this.props.setRank(v)}
        >
          <i className="now-ui-icons objects_diamond" />
        </div>
      );
      items--;
    }
    return diamonds.reverse();
  }

  render() {
    let { items } = this.state;
    if (items) {
      let list = this.generateRank();
      return <div>{list}</div>;
    }
    return null;
  }
}

export default Rank;
